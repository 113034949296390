export default class UtilService {
  constructor(http) {
    this._http = http
  }

  async devolveMesAnoAtual() {
    const { data } = await this._http.get('/api/utils/mes-ano-atual')
    return data
  }
}
